import React from 'react'

const Ctx = React.createContext()

const initialState = {
  lang: 'es',
  isModalOpen: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_LANGUAGE': {
      return {
        ...state,
        lang: action.payload,
      }
    }
    case 'TOGGLE_MODAL':
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      }
    default:
      return state
  }
}

export const GlobalContextProvider = ({ children }) => {
  const value = React.useReducer(reducer, initialState)
  return <Ctx.Provider value={value}>{children}</Ctx.Provider>
}

const useGlobalReducer = () => React.useContext(Ctx)

export default useGlobalReducer
